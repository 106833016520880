.single-service {
  text-align: center;
  background: #fff;
  position: relative;
  margin: 10px 0px;
  font-size: 19px;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  min-height: 450px;
  width: 100%;
  display: grid;
  grid-template-rows: min-content;

  .service-bg {
    display: flex;

    > img {
      width: 100%;
    }
  }

  .service-text {
    padding: 10px 16px 10px;
    font-weight: 400;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    > p {
      margin: 0;
    }
  }

  .service-button {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
  }
}

@media (min-width: 600px) {
  .single-service {
    &:hover {
      box-shadow: 0px 0px 20px 0px rgb(57, 56, 56);
      transform: scale(1.01);
      cursor: pointer;
    }
  }
}

@media (min-width: 400px) {
  .single-service {
    max-width: 350px;
    min-width: 350px;
  }
}
